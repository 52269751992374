.common__section {
   background: linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)),
   url("../assets/images/tour.jpg") no-repeat center center;
   height: 400px;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center;
   background-size: cover;
}

.common__section h1 {
   font-size: 2.5rem;
   color: #fff;
}