.service__item {
   padding      : 1rem;
   border-bottom: 1px solid #faa83586;
   border-right : 1px solid #faa83586;
   border-radius: 0.5rem;
}

.service__img {
   width          : 60px;
   height         : 50px;
   display        : flex;
   align-items    : center;
   justify-content: center;
   border-radius  : 50px;
   background     : var(--secondary-color);
   padding        : 0.5rem;
   margin-bottom  : 1rem;
   line-height    : 50px;
}

.service__img img {
   width: 100%;
}

.service__item h6 {
   font-size  : 1.1rem;
   font-weight: 500;
}

.service__item p {
   font-size: 0.8rem;
   color    : var(--text-color);
}

/* =============== RESPONSIVE ================ */
@media only screen and (max-width:992px) {
   
}