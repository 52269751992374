/* General Styles for the Slider Container */
.slider-container {
  width: 100%; /* Full width of the container */
  padding: 20px; /* Padding around the slider */
}

/* Cards Slider Flexbox */
.cards-slider {
  display: flex; /* Align cards in a row */
}

/* Individual Card Styles */
.card {
  flex: 0 0 auto; /* Prevent cards from growing or shrinking */
  width: 300px; /* Set width for each card */
  margin: 0 10px; /* Adjust space between cards */
  background-color: #fff; /* Card background */
  border: 1px solid #ddd; /* Card border */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  padding: 15px; /* Inner padding */
  text-align: left; /* Align text to the left */
}

/* Card Image */
.card-image {
  width: 100%; /* Full width for the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Rounded corners for image */
}

/* Card Title and Details */
.card-title {
  font-size: 18px; /* Font size for card title */
  margin-bottom: 10px; /* Space below title */
}

.card-price {
  font-size: 16px; /* Font size for price */
  margin-bottom: 5px; /* Space below price */
}

.card-details {
  list-style: none; /* Remove list style */
  padding: 0; /* Remove padding */
  margin: 10px 0; /* Margin around details */
}

/* Card Button */
.card-button {
  background-color: #FAA935; /* Button background color */
  color: white; /* Button text color */
  padding: 8px 15px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.card-button:hover {
  background-color: #e8a63c; /* Darker shade on hover */
}

/* Hide scrollbar for the cards-slider */
.cards-slider::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .card {
    width: 80%; /* Adjust width for smaller screens */
  }
}

@media (min-width: 600px) {
  .card {
    width: 250px; /* Wider cards for larger screens */
  }
}

@media (min-width: 900px) {
  .card {
    width: 300px; /* Even wider cards for larger screens */
  }
}
