.tour-slider {
    padding: 20px 0;
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 40px;
    color: #333;
  }
  